
































































































































































import { Vue, Component } from "vue-property-decorator";
import { convertToNumber } from "@/helpers/tools";
import { Course } from "@/types";
import axios, { AxiosRequestHeaders, AxiosError, AxiosResponse } from "axios";

@Component({
  metaInfo() {
    return {
      title: "Oluştur / Kullanıcılar / Admin"
    };
  }
})
export default class AdminUserCreateView extends Vue {
  form = {
    firstName: "",
    lastName: "",
    password: "",
    passwordConfirm: "",
    email: "",
    phone: "",
    tckn: "",
    gender: "",
    eduLevel: "",
    city: "",
    address: "",
    profession: ""
  };

  submitDisabled = false;
  
  gender = ["Kadın", "Erkek"];

  eduLevels = ["İlköğretim", "Lise", "Ön Lisans", "Lisans", "Yüksek Lisans", "Doktora"];

  get firstNameRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen adınızı yazınız"];
  }

  get lastNameRules(): unknown[] {
    return [(v: string) => !!v || "Lütfen soyadınızı yazınız"];
  }

  get emailRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen e-postanızı yazınız",
      (v: string) => /.+@.+\..+/.test(v) || "Geçersiz e-posta adresi"
    ];
  }

  get passwordRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen parolanızı yazınız",
      (v: string) =>
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*'<>"+/\\()=?\-_|£[\]{}.,éıİöÖçÇşŞğĞüÜ]).{8,}$/g.test(v) ||
        "Parolanız en az 8 karakter olmalıdır. En az 1 büyük harf, 1 küçük harf, 1 sayı ve 1 özel karakter içermelidir."
    ];
  }

  get passwordConfirmRules(): unknown[] {
    return [
      (v: string) => !!v || "Lütfen parolanızı onaylayınız",
      (v: string) => v === this.form.password || "Parola onaylanmadı"
    ];
  }

  validate(): boolean {
    const form = this.$refs.form as HTMLFormElement;
    return form.validate();
  }

  reset(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.reset();
  }

  resetValidation(): void {
    const form = this.$refs.form as HTMLFormElement;
    form.resetValidation();
  }

  async create() {
    if (this.validate()) {
      try {
        this.submitDisabled = true;

        const res = await axios.post("/api/user/create", {
          Username: this.form.email,
          Email: this.form.email,
          Password: this.form.password,
          FirstName: this.form.firstName,
          LastName: this.form.lastName,
          Phone: this.form.phone,
          Tckn: this.form.tckn,
          Gender: this.form.gender,
          EduLevel: this.form.eduLevel,
          Profession: this.form.profession,
          Address: this.form.address
        }, {
          headers: this.$store.getters["auth/requestAuthHeader"]
        });

        this.$notify({ type: "success", text: "Kullanıcı başarıyla oluşturuldu. Lütfen bekleyiniz..." });

        const timer1 = setTimeout(() => {
          this.$router.push({ name: "AdminUsers" });

          clearTimeout(timer1);
        }, 3000);
      } catch (e: unknown | AxiosError) {
        if (axios.isAxiosError(e)) {
          this.$notify({ type: "error", text: e.response?.data.message});
        } else {
          this.$notify({ type: "error", text: "Beklenmedik bir hata nedeniyle kullanıcı oluşturulamadı." });
        }
      } finally {
        this.submitDisabled = false;
      }
    }
  }
}
